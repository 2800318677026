<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">LIST OF LESSEE</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md4>
            <v-select
              v-model="type_of_member"
              dense
              outlined
              label="Type of Member"
              :items="['All','Individual','Business']"
              :rules="rules.combobox_rule"
              @change="load_data"
            ></v-select>
          </v-flex>
        </v-layout>
        <list-of-lessee :key="this.key" :type_of_business="this.type_of_member"/>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
  import {
    mdiAccountGroup
    , mdiAccountSearch
  } from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import ListOfLessee
    from "@/views/register_interface/lessee_component/ListOfLessee";

  export default {
    components: {
      ListOfLessee,
    },
    data() {
      return {
        icons: {
          mdiAccountGroup,
          mdiAccountSearch,
        },
        key: 0,
        type_of_member: 'All',
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      load_data() {
        this.key++
      },
    }
  }
</script>
